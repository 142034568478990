<template>
  <div id="ConsultantSettings">
    <content-title :nav="nav"></content-title>
    <el-form style="margin-top: 20px" :inline="true" class="demo-form-inline">
      <el-form-item label="姓名">
        <el-input v-model="name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="search" style="color: #ffffff; background-color: #ff7e00"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table :data="tableData">
      <el-table-column prop="name" label="姓名">
      </el-table-column>
      <el-table-column prop="city" label="顾问所在城市"></el-table-column>
      <el-table-column label="状态">
        <template v-slot="{row}">
          <el-tag v-if="row.status == 2">正常</el-tag>
          <el-tag v-if="row.status == 3" type="warning">冻结</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{row}">
          <el-button type="primary" size="small" @click="todetails(row)">查看详情</el-button>
          <el-button type="warning" size="small" @click="updateStatus(row,3)" v-if="row.status == 2">冻结</el-button>
          <el-button type="success" size="small" @click="updateStatus(row,2)" v-if="row.status == 3">解冻</el-button>

        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="currentPageChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "顾问管理中心", secondNav: "顾问设置" },
      tableData: [],
      currentPage:1,
      total:0,
      name:"",
      manager: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },
created(){
  this.getList()
},
  methods: {
    search(){
      this.currentPage =1
      this.getList()
    },
    todetails(row){
      this.$router.push({
        name:'consultantDetails',
        params:{
          id:row.id
        }
      });
    },
updateStatus(row,status){
  let data ={
       id:row.id,
       status
      }
      let url = "/authorization/managerUser/update";
          this.$axios
            .post(url, data)
            .then((res) => {
              this.$message.success("成功")
              this.getList()
            });
},
    getList(){
      let data ={
        pageSize:10,
        currentPage:this.currentPage,
        type:'consultant',
        name:this.name || null
      }
      if (this.manager.type == 'consultant') {
                data.admin_id = this.manager.id
            }
      let url = "/authorization/managerUser/queryManagerListPage";
          this.$axios
            .get(url, {
              params: data,
            })
            .then((res) => {
              this.tableData = res.data.data.rows
              this.total = res.data.data.total
            });
    },
    currentPageChange(v) {
      this.currentPage=v
      this.getList()
    },
  },
};
</script>

<style lang="less">
#ConsultantSettings {
  background-color: #ffffff;
  padding: 0 20px;
}
.el-form-item {
  margin-right: 50px !important;
}
.cell {
  text-align: center;
}
</style>
